<template>
  <section class="vote">
    <div class="mv" />
    <div class="vote__textWrap">
      <h2 class="vote__header">組み合わせ情報</h2>
      <img src="../assets/images/tournamentInfo/tournamentInfo_1.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_2.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_3.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_4.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_5.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_6.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_7.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_8.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_9.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_10.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_11.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_12.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_13.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_14.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_15.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_16.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_17.jpg" />
      <img src="../assets/images/tournamentInfo/tournamentInfo_18.jpg" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.vote {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  max-width: 1240px;
  margin: 120px auto 0;
  padding-top: 100px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 60px;
    padding: 0 20px;
  }
}

.vote__img {
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    order: 2;
  }
  img {
    width: 100%;
    border-radius: 15px;
  }
}

.vote__textWrap {
  width: 50%;
  margin-left: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    order: 1;
  }
}

.vote__text {
  &--notice {
    font-size: 14px;
    font-weight: bold;
  }
}

.vote__button {
  margin-top: 40px;
}

.c-primaryButton {
  margin-top: 20px;
}
</style>

<script></script>
